.hobbies {
  position: absolute;
  display: flex;
  justify-content: space-around;
  background-color: white;
  border-radius: 20px;
  width: 85%;
  box-shadow: 1px 5px 1px whitesmoke;
  top: 350px;
  left: 7.5%;
  z-index: 10;
}

.hobbies__column1,
.hobbies__column2,
.hobbies__column3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0px;
  border-right: 1px solid whitesmoke;
  border-left: 1px solid whitesmoke;
  border-bottom: 1px solid whitesmoke;
  flex: 1;
  min-width: 200px;
  background-color: white;
}

.hobbies__column1 {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}

.hobbies__column3 {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

.hobbies__img {
  height: 40px;
  object-fit: contain;
}

.hobbies__title {
  font-size: 24px;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.hobbies__p {
  font-size: 18px;
  padding: 8px 30px;
  line-height: 1.75;
}

@media (max-width: 740px) {
  .hobbies {
    flex-wrap: wrap;
  }

  .hobbies__column1,
  .hobbies__column2,
  .hobbies__column3 {
    min-width: 320px;
  }
  .hobbies__column1 {
    border-bottom-left-radius: 0;
    border-top-right-radius: 20px;
  }

  .hobbies__column3 {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 0px;
  }
}

@media (max-width: 740px) {
  .hobbies {
    top: 400px;
  }
}

@media (max-width: 500px) {
  .hobbies__column1,
  .hobbies__column3,
  .hobbies__column2 {
    min-width: 250px;
  }
}

@media (max-width: 400px) {
  .hobbies {
    top: 450px;
  }
}
