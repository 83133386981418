.card {
  height: 255px;
  width: 380px;
  margin: 20px;
  border-radius: 10px;
  display: flex;
  text-align: center;
  position: relative;
  cursor: pointer;
}

.card__overlay > img {
  height: 255px;
  width: 380px;
  object-fit: cover;
  opacity: 100;
  display: inline;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  z-index: 0;
}

.card__main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  z-index: 5;
  background-color: var(--main-light-blue);
  opacity: 0;
  transition: all 0.45s ease-in-out;
  border-radius: 10px;
}

.card__main:hover {
  opacity: 100;
  transition: opacity 0.45s ease-in-out;
}

.card__main > p {
  color: white;
  font-size: 25px;
  margin-bottom: 15px;
  margin-left: 30px;
  margin-right: 30px;
}

.card__button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-left: 30px;
  margin-right: 30px;
  position: static;
}

.card__button > a {
  letter-spacing: 2px;
  text-align: center;
  color: var(--main-dark-blue);
  border-radius: 999px;
  outline-width: 0ch;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;

  font-size: 18px;
  font-weight: 300;

  background: white;
  border: 1px solid white;
  overflow: hidden;
  width: 150px;

  transition: 0.25s ease-in-out;
}

.card__button > a:hover {
  color: white;
  background-color: var(--main-dark-blue);
  border: var(--main-dark-blue);
  transition: all 0.45s ease-in-out;
}

.card__button > a > span {
  display: inline-block;
}

@media (max-width: 1350px) {
  .card {
    height: 221px;
    width: 330px;
    margin: 20px;
  }

  .card__overlay > img {
    height: 221px;
    width: 330px;
  }

  .card__main {
    width: inherit;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .card__main > p {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .card__button {
    margin-top: 10px;
    margin-left: 30px;
    margin-right: 30px;
  }

  .card__button > a {
    font-size: 18px;
  }
}

@media (max-width: 1140px) {
  .card {
    height: 201px;
    width: 300px;
    margin: 20px;
  }

  .card__overlay > img {
    height: 201px;
    width: 300px;
  }

  .card__main {
    width: inherit;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .card__main > p {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .card__button {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .card__button > a {
    font-size: 18px;
  }
}

@media (max-width: 700px) {
  .card {
    height: 255px;
    width: 380px;
    margin: 20px;
  }

  .card__overlay > img {
    height: 255px;
    width: 380px;
  }

  .card__main {
    width: inherit;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .card__main > p {
    margin-bottom: 15px;
    font-size: 25px;
    margin-left: 30px;
    margin-right: 30px;
  }

  .card__button {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .card__button > a {
    font-size: 20px;
  }
}

@media (max-width: 470px) {
  .card {
    height: 221px;
    width: 330px;
    margin: 20px;
  }

  .card__overlay > img {
    height: 221px;
    width: 330px;
  }

  .card__main {
    width: inherit;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .card__main > p {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .card__button {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .card__button > a {
    font-size: 18px;
  }
}

@media (max-width: 390px) {
  .card {
    height: 201px;
    width: 300px;
    margin: 20px;
  }

  .card__overlay > img {
    height: 201px;
    width: 300px;
  }

  .card__main {
    width: inherit;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .card__main > p {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .card__button {
    margin-top: 10px;
  }

  .card__button > a {
    font-size: 18px;
  }
}

@media (max-width: 350px) {
  .card {
    height: 174px;
    width: 270px;
  }

  .card__overlay > img {
    height: 174px;
    width: 260px;
  }

  .card__main {
    width: inherit;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .card__main > p {
    font-size: 15px;
    margin-bottom: 10px;
  }

  .card__button > a {
    font-size: 15px;
    padding: 7px 10px;
    width: auto;
  }
}
