.nostyle:link {
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;
  }
  
  .nostyle:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;
  }

.menu{
    position: absolute;
    top:57px;
    left: 0;
    color: black;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 400px;
    height: 100vh;
    transition: all .30s;
    font-size: 20px;
    z-index: 100;
}

.menu__container{
    width: fit-content;
    height: fit-content;
    margin-top: -20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.menu__container>p {
    padding: 20px 70px;
    letter-spacing: 3px;
    cursor: pointer;
    color: black;
    text-decoration: none;
    flex-wrap: wrap;
    text-align: center;
}
