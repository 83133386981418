
:root{
  --main-dark-blue: #5680E9;
  --main-blue: #5AB9EA;
  --main-light-blue: #84ceeb;
  --main-purple:#8860d0;
  --main-grey: #c1c8e4;
}

.app {
  background: linear-gradient(180deg, white 75%, var(--main-dark-blue) 100%);
}

