.header__container{
  position: sticky;
  top: 0;
  z-index: 99;
  transition: all .45s;
  
}

.header {
  padding: 12px 40px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all .45s;
  
}

.headerWhite {
  padding: 12px 40px;
  background-color: white;
  opacity: 0.8;
  display: flex;
  z-index: 100;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  transition: all .45s; 

}

.header__titleMb{
  display: none;
}

.header__title {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
}

.header__titleText{
  display: flex;
  flex-direction: column;
}

.header__titleLogo{
  height:35px;
  object-fit: contain;
  padding-right: 20px;
}

.header__titleText> h2 {
  background: -webkit-linear-gradient(#eee, var(--main-purple));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header__titleText> h2:hover {
  background: -webkit-linear-gradient(#eee, var(--main-blue));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header__titleText> p {
  font-size: 15px;
  font-weight: 600;
  background: -webkit-linear-gradient(#eee, var(--main-purple));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header__optionsMenuMb{
  display: none;
}

.header__options {
  display: flex;
}

.header__options > p {
  cursor: pointer;
  padding-left: 20px;
  background: -webkit-linear-gradient(
    var(--main-dark-blue),
    var(--main-purple)
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
}

.header__options > p:hover {
  background: -webkit-linear-gradient(var(--main-light-blue), var(--main-blue));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}


@media(max-width: 700px){

  .header__options{
    display: none;
  }
  .header__title{
    display: none;
  }

  .header__container{
    position: sticky;
    transition: all .10s; 
  }
  .header {
    padding: 15px 15px 15px 35px;
    transition: all .30s;
  }

  .headerWhite {
    padding: 15px;
    background-color: white;
    opacity: 0.8;
    display: flex;
    z-index: 100;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    transition: all .30s; 
  
  }
  .header__titleMb {
    display: flex;
    cursor: pointer;
    display:flex;
    align-items: center;
  }
  
  .header__titleMb > img {
    /* background: -webkit-linear-gradient(#eee, var(--main-purple)); */
    /* -webkit-background-clip: text; */
    /* background-clip: text; */
    /* -webkit-text-fill-color: transparent; */
    height: 30px;
    object-fit: contain;
  }
  .header__optionsMenuMb{
    display: flex;
    font-size: 50px;
    cursor: pointer;
    color: 
      var(--main-blue)
  }
 
}

