@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
}

p {
  font-family: "Open Sans", sans-serif;
}

h1,
h2,
h3,
h4 {
  font-family: "Bodoni Moda", serif;
}

a,
button,
div {
  -webkit-tap-highlight-color: transparent;
}
