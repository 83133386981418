.projects {
  height: 100%;
  position: relative;
}

.projects__intro {
  background: linear-gradient(
    140deg,
    white 10%,
    var(--main-grey) 40%,
    var(--main-light-blue) 70%,
    var(--main-dark-blue) 100%
  );
  display: flex;
  position: absolute;
  width: 100%;
  top: -80px;
  flex-direction: column;
  align-items: center;
  height: 400px;
  justify-content: center;
  text-shadow: 1px 1px 2px var(--main-grey);
  color: white;
  border-bottom: 1px solid var(--main-grey);
  transition: all 0.3s;
}

.projects__intro > h3 {
  font-size: 45px;
  padding: 15px 30px 15px 30px;
  text-align: center;
}

.projects__intro > p {
  text-align: center;
  font-size: 25px;
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
}

.projects__job {
  padding: 400px 0 0 0;
  font-size: 18px;
}

.projects__job img {
  padding: 20px 0 0 0;
  height: 80px;
  width: auto;
}

.projects__line {
  width: 90%;
  max-width: 450px;
  height: 2px;
  background: linear-gradient(
    140deg,
    var(--main-dark-blue) 10%,
    var(--main-grey) 40%,
    var(--main-light-blue) 70%,
    var(--main-dark-blue) 100%
  );
}

.projects__allRowsTwo {
  display: none;
}
.projects__allRows {
  padding-bottom: 100px;
  padding-top: 100px;
}

.projects__row {
  padding: 5px 40px;
  display: flex;
  justify-content: center;
}

.projects__other {
  display: flex;
  position: relative;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 400px;
  background: linear-gradient(
    150deg,
    white 5%,
    var(--main-grey) 25%,
    var(--main-light-blue) 65%,
    var(--main-dark-blue) 100%
  );
  padding-top: 110px;
  padding-bottom: 180px;
  flex-wrap: wrap;
}

.projects__otherText {
  font-size: 18px;
  max-width: 800px;
  padding-bottom: 30px;
  padding-left: 40px;
  padding-right: 40px;
  align-self: center;
  line-height: 2;
  color: white;
}

.projects__otherTitle {
  display: flex;
  flex-direction: column;
  font-size: 30px !important;
  color: white;
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 30px;
}

@media (max-width: 1200px) {
  .projects__other {
    margin-bottom: 480px;
  }
}

@media (max-width: 1050px) {
  .projects__allRows {
    display: none;
  }
  .projects__allRowsTwo {
    display: flex;
    flex-direction: column;
    padding-bottom: 100px;
    padding-top: 70px;
  }

  .projects__other {
    margin-bottom: 510px;
  }
}

@media (max-width: 850px) {
  .projects__other {
    margin-bottom: 600px;
  }
}

@media (max-width: 740px) {
  .projects__other {
    margin-bottom: 900px;
  }
}

@media (max-width: 700px) {
  .projects__intro > h3 {
    font-size: 30px;
  }
  .projects__intro > p,
  .projects__job p {
    font-size: 17px;
  }
  .project__allRowsTwo {
    display: none;
  }
  .project__allRows {
    display: flex;
  }
  .projects__row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .projects__other {
    margin-bottom: 950px;
  }
}

@media (max-width: 565px) {
  .projects__other {
    margin-bottom: 1000px;
  }
}

@media (max-width: 510px) {
  .projects__other {
    margin-bottom: 1050px;
  }
}

@media (max-width: 425px) {
  .projects__other {
    margin-bottom: 1100px;
  }
}

@media (max-width: 401px) {
  .projects__other {
    margin-bottom: 1250px;
  }
}

@media (max-width: 335px) {
  .projects__other {
    margin-bottom: 1320px;
  }
}

@media (max-width: 315px) {
  .projects__other {
    margin-bottom: 1330px;
  }
}

@media (max-width: 308px) {
  .projects__other {
    margin-bottom: 1380px;
  }
}
