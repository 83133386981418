.nostyle:link,
.about__titleEmail:link {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}

.nostyle:visited,
.about__titleEmail:visited {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}

.about {
  background: inherit;
  position: relative;
  z-index: 2;
  transition: all 0.3s;
}
.about__videoPreload {
  position: absolute;
  top: -80px;
  z-index: -2;
  width: 100%;
  height: 420px;
  background-color: #c1ccd4;
}

.about__video {
  position: absolute;
  top: -80px;
  z-index: -1;
  width: 100%;
  height: 420px;
  object-fit: cover;
  border-bottom: 1px solid var(--main-grey);
}

.about__titleMb {
  display: none;
}

.about__title {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  height: 420px;
  color: whitesmoke;
  font-weight: 200;
}

.about__title > p {
  font-size: 75px;
  text-align: center;
  margin-top: -150px;
  text-shadow: 1px 1px 2px rgb(168, 168, 168);
  padding-left: 45px;
  padding-right: 45px;
}

.about__titleEmail:hover {
  color: var(--main-blue);
}

.about__avatar {
  height: 150px;
  object-fit: contain;
  width: auto;
  padding-bottom: 30px;
}

.about__bottom {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
}

.about__bottomText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  padding-bottom: 130px;
}

.about__bottomTextMb {
  display: none;
}

.about__bottomText > p {
  padding: 5px;
}

.about__titleEmail:hover {
  color: var(--main-blue);
}

.about__bottomText > h4 {
  padding-top: 20px;
  font-family: "Bodoni Moda", serif;
  font-weight: 100;
}

.about__bottomTextButton {
  letter-spacing: 2.5px;
  text-align: center;
  color: white;
  border-radius: 999px;
  outline-width: 0ch;
  cursor: pointer;
  font-size: 25px;
  font-weight: 200;
  flex-wrap: nowrap;
  margin-top: 60px;

  width: 340px;
  height: 50px;

  background: var(--main-purple);
  border: 1px solid var(--main-purple);
  overflow: hidden;

  transition: all 0.5s;
}

.about__bottomTextButton:hover,
.about__bottomTextButton:active {
  text-decoration: none;
  color: var(--main-purple);
  border-color: var(--main-purple);
  background: #fff;
}

.about__bottomTextButton > span {
  display: inline-block;
  position: relative;

  padding-right: 0;

  transition: padding-right 0.5s;
}

.about__bottomTextButton > span:after {
  content: " ";
  position: absolute;
  top: 0;
  right: -18px;
  opacity: 0;
  width: 10px;
  height: 10px;
  margin-top: -10px;

  background: rgba(0, 0, 0, 0);
  border: 3px solid #fff;
  border-top: none;
  border-right: none;

  transition: opacity 0.5s, top 0.5s, right 0.5s;
  transform: rotate(-45deg);
}

.about__bottomTextButton:hover span,
.about__bottomTextButton:active span {
  padding-right: 30px;
}

.about__bottomTextButton:hover span:after,
.about__bottomTextButton:active span:after {
  transition: opacity 0.5s, top 0.5s, right 0.5s;
  opacity: 1;
  border-color: var(--main-light-blue);
  right: 0;
  top: 50%;
}

.about__praise {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 350px;
  background: linear-gradient(
    150deg,
    white 5%,
    var(--main-grey) 25%,
    var(--main-light-blue) 65%,
    var(--main-dark-blue) 100%
  );
  padding-top: 110px;
  padding-bottom: 330px;
  flex-wrap: wrap;
}

.about__praiseText {
  font-size: 18px;
  max-width: 800px;
  padding-bottom: 30px;
  padding-left: 40px;
  padding-right: 40px;
  align-self: center;
  line-height: 2;
  color: white;
}

.about__praiseAuthor {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: white;
}

.about__praiseAuthor > span {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 5px;
}

.about__footerMb {
  display: none;
}

.about__buttons {
  display: flex;
  padding: 100px 20px 20px 20px;
}

.about__buttons > button {
  letter-spacing: 2px;
  text-align: center;
  color: var(--main-dark-blue);
  border-radius: 999px;
  outline-width: 0ch;
  cursor: pointer;
  font-size: 20px;
  font-weight: 300;

  margin: 15px 30px;

  width: 150px;
  height: 45px;

  background: white;
  border: 1px solid white;
  overflow: hidden;

  transition: all 0.5s;
}

.about__buttons > button:hover,
.about__buttons > button:active {
  text-decoration: none;
  color: var(--main-light-blue);
  border-color: var(--main-light-blue);
  background: #fff;
}

.about__buttons > button > span {
  display: inline-block;
  position: relative;

  padding-right: 0;

  transition: padding-right 0.5s;
}

.about__buttons > button > span:after {
  content: " ";
  position: absolute;
  top: 0;
  right: -18px;
  opacity: 0;
  width: 10px;
  height: 10px;
  margin-top: -10px;

  background: rgba(0, 0, 0, 0);
  border: 3px solid #fff;
  border-top: none;
  border-right: none;

  transition: opacity 0.5s, top 0.5s, right 0.5s;
  transform: rotate(-45deg);
}

.about__buttons > button:hover span,
.about__buttons > button:active span {
  padding-right: 30px;
}

.about__buttons > button:hover span:after,
.about__buttons > button:active span:after {
  transition: opacity 0.5s, top 0.5s, right 0.5s;
  opacity: 1;
  border-color: var(--main-light-blue);
  right: 0;
  top: 50%;
}

@media (max-width: 1600px) {
  .about__title > p {
    font-size: 60px;
  }
}

@media (max-width: 900px) {
  .about__buttons > button {
    font-size: 18px;
    margin: 15px 25px;
    width: 120px;
    height: 45px;
  }
}

@media (max-width: 800px) {
  .about__buttons > button {
    font-size: 15px;
    margin: 15px 17px;
    width: 120px;
    height: 40px;
  }
}

@media (max-width: 740px) {
  .about__praise {
    margin-bottom: 1220px;
  }
}

@media (max-width: 700px) {
  .about__video {
    display: none;
  }

  .about__ video > source {
    display: none;
  }

  .about__bottomText {
    display: none;
  }
  .about__buttons {
    display: none;
  }
  .about__title {
    display: none;
  }

  .about__videoPreload {
    background: linear-gradient(
      140deg,
      white 10%,
      var(--main-grey) 40%,
      var(--main-light-blue) 70%,
      var(--main-dark-blue) 100%
    );
    height: 400px;
  }

  .about__titleMb {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 400px;
    color: whitesmoke;
  }

  .about__titleMbBigP {
    font-size: 35px;
    text-align: center;
    margin-top: -130px;
    text-shadow: 1px 1px 2px rgb(168, 168, 168);
    padding-left: 40px;
    padding-right: 40px;
    font-weight: 300;
    color: whitesmoke;
  }

  .about__titleMbSmallP {
    font-size: 18px;
    text-align: center;
    padding-top: 18px;
    font-weight: 200;
    padding-right: 50px;
    padding-left: 50px;
  }

  .about__avatarMb {
    height: 150px;
    width: auto;
    margin-top: 60px;
    padding: 20px;
    background-color: white;
    box-shadow: 1px 5px 1px whitesmoke;
    border-radius: 20px;
  }

  .about__bottom {
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;
  }

  .about__bottomTextMb {
    padding-right: 40px;
    padding-left: 40px;
    padding-bottom: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 2;
    font-size: 18px;
  }

  .about__bottomTextMb > h4 {
    margin-top: 10px;
    font-size: 18px;
  }

  .about__bottomTextButton {
    letter-spacing: 2.5px;
    font-size: 22px;
    font-weight: 300;
    margin-top: 45px;
    margin-bottom: 20px;
    width: 220px;
    height: 45px;
  }

  .about__bottomTextButton:hover span,
  .about__bottomTextButton:active span {
    padding-right: 25px;
  }

  .about__footerMb {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .about__bottomTextButton {
    letter-spacing: 2px;
    font-size: 20px;
    font-weight: 300;
    margin-top: 45px;
    margin-bottom: 20px;
    width: 200px;
    height: 43px;
  }
  .about__bottomTextButton:hover span,
  .about__bottomTextButton:active span {
    padding-right: 20px;
  }
}
