.nostyle:link {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}

.nostyle:visited {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  color: white;
}

.footer > img {
  height: 55px;
  object-fit: contain;
  padding-bottom: 15px;
}

.footer > h4 {
  font-size: 20px;
  font-weight: 300;
  text-shadow: 1px 1px 2px var(--main-grey);
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
}

.footer__icons {
  display: flex;
  align-items: center;
  transition: all 1s;
  padding-bottom: 20px;
}

.footer__icon {
  padding: 10px;
  border: 1px solid white;
  border-radius: 999px;
  margin: 20px;
  cursor: pointer;
}

.footer__icon:hover {
  background: white;
  color: var(--main-purple);
  transition: all 0.45s;
}

.footer > p {
  font-size: 12px;
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
}

@media (max-width: 740px) {
  .footer {
    padding-top: 100px;
  }
}

@media (max-width: 700px) {
  .footer {
    padding-bottom: 60px;
  }
  .footer > h4 {
    font-size: 15px;
  }
  .footer__icon {
    padding: 8px;
  }
}

@media (max-width: 471px) {
  .footer {
    padding-top: 50px;
  }
}
